import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import { selectLoggedInStatus } from '../../redux/selectors';

// eslint-disable-next-line react/prop-types
const Protected = ({ component, exact, path }) => {
  const loggedInStatus = useSelector(selectLoggedInStatus);
  return (
    <Route
      path={path}
      render={({ location }) => {
        if (loggedInStatus.loggedIn === true) {
          return component;
        }
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
            push={false}
          />
        );
      }}
      exact={exact}
    />
  );
};

export default Protected;
