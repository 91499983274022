/* eslint-disable no-nested-ternary */
const buttonize = (handlerFn) => ({
  role: 'button',
  onClick: handlerFn,
  tabIndex: 0,
  onKeyDown: (event) => {
    // insert your preferred method for detecting the keypress
    if (event.keycode === 13) handlerFn(event);
  },
});

export const assetPrefix = '/oddup-app/assets';

export const loadingStates = {
  NO_ACTIVE_REQUEST: 'NO_ACTIVE_REQUEST',
  LOADING: 'LOADING',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
  SUCCEEDED: 'SUCCEEDED',
};

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePassword(pwd) {
  const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
  return pwd.match(regex);
}

export const optionsGenerator = (options, selectedOptions) => {
  const allSelectedValues = selectedOptions.map((option) => option.value);
  return options.filter(
    (option) => allSelectedValues.indexOf(option.value) === -1,
  );
};

export const localDropdownSearch = (search, selectedOptions, allOptions) => {
  if (!allOptions || allOptions[0].label === null) {
    return [];
  }
  return optionsGenerator(
    allOptions.filter(
      (option) => option.label.toLowerCase().indexOf(search.toLowerCase()) === 0,
    ), selectedOptions,
  );
};

export const bodyScrollLock = () => {
  if (document && document.body) document.body.style.overflowY = 'hidden';
  return undefined;
};

export const bodyScrollUnLock = () => {
  if (document && document.body) document.body.style.overflowY = 'scroll';
  return undefined;
};

export const getColorForScore = (score) => {
  if (score >= 70) return '#8CE217';

  if (score > 35) return '#F9A61A';

  return '#ED2D27';
};

export const fixed2Decimals = (val) => (val * 1).toFixed(2).replace(/[.,]00$/, '');

export const displayableValue = (val) => Math.floor(val);

export const sliderValuesFormatter = (maxInMillions, value) => {
  const millions = (maxInMillions / 100) * value;
  const billions = millions / 1000;
  const trillions = billions / 1000;
  const thousand = millions * 1000000;
  return (
    {
      millions,
      billions,
      thousand,
      trillions,
      toShow: millions < 1000 ? `${Math.round(millions)}M` : billions < 1000 ? `${billions}B` : `${trillions}T`,
    }
  );
};

export const oddupScoreColor = (score) => {
  if (score >= 70) {
    return '#4eca2c';
  } if (score <= 35) {
    return '#ed2d27';
  }
  return '#f2c62a';
};

export const millifyOptions = ({
  precision: 2,
  units: ['', 'K', 'M', 'B', 'T'],
});

export const millifyOptionsForCrypto = ({
  precision: 6,
  units: ['', 'K', 'M', 'B', 'T'],
});

export const setArrowColor = (score) => {
  if (score.charAt(0) === '-') {
    return {
      value: score.slice(1),
      color: '#D13333',
    };
  }
  return {
    value: score.slice(1),
    color: '#2ED86E',
  };
};

export const multipleTextToOne = (arr) => {
  if (arr.length === 1) return arr[0];
  return `${arr[0]}, +${arr.length - 1} others`;
};

export const getDateFromISODate = (state) => {
  const months = ['Jan', 'Feb', 'Mar', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  let date = state === null ? '' : state && state.map((date1) => new Date(date1[0]));

  date = date && date.map((Ndate) => (Ndate ? `${months[Ndate.getMonth()]} ${Ndate.getDate()}` : '-'));

  return date;
};

export const numeralFormat = '0,0.[0]00000';

export default buttonize;
