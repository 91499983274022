import { createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import rootReducer from './reducers';
import { loadingStates } from '../utilities/general';

const initialStore = {
  initialValues: {},
  userProfile: { loading: loadingStates.NO_ACTIVE_REQUEST, data: null },
  userSettings: { loading: loadingStates.NO_ACTIVE_REQUEST, data: null },
  loggedInStatus: { loggedIn: false },
  warningRibbon: {
    show: false,
  },
  limitExceedError: {
    occurred: false,
    pathname: null,
  },
};

const store = createStore(rootReducer, initialStore, devToolsEnhancer(
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
));

export default store;
