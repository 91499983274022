export const selectLocations = (store) => store.initialValues.locations;

export const selectIndustries = (store) => store.initialValues.industries;

export const selectTags = (store) => store.initialValues.tags;

export const selectUserProfile = (store) => store.userProfile;

export const selectUserSettings = (store) => store.userSettings;

export const selectLoggedInStatus = (store) => store.loggedInStatus;

export const selectWarningRibbon = (store) => store.warningRibbon;

export const selectLimitExceedError = (store) => store.limitExceedError;

// eslint-disable-next-line max-len
export const selectFavouriteSlugsTabWise = (tabName) => (store) => (store.userSettings.data
  ? store.userSettings.data.favourites[tabName] : []);
