import axios from 'axios';
import constants from '../../constants';
import { headersProvider } from '../apiHelpers';

// eslint-disable-next-line import/prefer-default-export
export const adminLogin = ({
  accessToken,
}) => axios.post(`${constants.apiAuthHost}/login`, {
  access_token: accessToken,
}, {
  noTrailingSlash: true,
  headers: headersProvider(),
});
