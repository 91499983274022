const pricingPlansDev = {
  forSales: {
    yearly: {
      name: 'Oddup for Sales',
      priceId: 'price_1JRwKTGsInqd0xDGCeD64lZF',
      priceInUSD: 299,
    },
    monthly: {
      name: 'Oddup for Sales',
      priceId: 'price_1JGGhUGsInqd0xDGGScRiWc1',
      priceInUSD: 29,
    },
  },
  forInvestors: {
    yearly: {
      name: 'Oddup for Investors',
      priceId: 'price_1JRwL5GsInqd0xDG8JdOGmwz',
      priceInUSD: 499,
    },
    monthly: {
      name: 'Oddup for Investors',
      priceId: 'price_1JGGicGsInqd0xDGyHSgOn1e',
      priceInUSD: 49,
    },
  },
};

const pricingPlansProd = {
  forSales: {
    yearly: {
      name: 'Oddup for Sales',
      priceId: 'price_1JMlYEGsInqd0xDGc2T5F0Nd',
      priceInUSD: 299,
    },
    monthly: {
      name: 'Oddup for Sales',
      priceId: 'price_1JMlYEGsInqd0xDGo7z1z1ff',
      priceInUSD: 29,
    },
  },
  forInvestors: {
    yearly: {
      name: 'Oddup for Investors',
      priceId: 'price_1JMlaeGsInqd0xDGzwkUmfvb',
      priceInUSD: 499,
    },
    monthly: {
      name: 'Oddup for Investors',
      priceId: 'price_1JMlaeGsInqd0xDGrvVcklLa',
      priceInUSD: 49,
    },
  },
};

const constants = {
  apiHost: process.env.REACT_APP_API_HOST,
  apiAuthHost: process.env.REACT_APP_AUTH_API_HOST,
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  pricingPlans: ((process.env.REACT_APP_ENV || '').toLowerCase() === 'production') ? pricingPlansProd : pricingPlansDev,
};

export default constants;
