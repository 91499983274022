/**
 * React
 * Author: Patanjali kumar (patanjali@oddup.com)
 * Copyright © 2020-present Oddup. All rights reserved.
 *
 This component will rendered all the time, all root / app booting time logic handling will
 be done through here
 */
// import '../../utilities/chartjs-financial-plugin';
import { arrayOf, node, oneOfType } from 'prop-types';

const MainWrapper = (props) => {
  const { children } = props;
  // const dispatch = useDispatch();
  // const userProfile = useSelector(selectUserProfile);
  // const userSettings = useSelector(selectUserSettings);
  // const loggedInStatus = useSelector(selectLoggedInStatus);

  // useEffect(() => {
  //   (async () => {
  //     dispatch({
  //       type: actions.SET_USER_SETTINGS,
  //       payload: {
  //         ...userSettings,
  //         loading: loadingStates.LOADING,
  //         data: null,
  //       },
  //     });
  //     const response = await apiWrapWithoutData(getAccountDetails());
  //     if (response && response.data && response.data.success) {
  //       dispatch({
  //         type: actions.SET_USER_SETTINGS,
  //         payload: {
  //           ...userProfile,
  //           loading: loadingStates.FINISHED,
  //           data: response.data.accountDetails,
  //         },
  //       });
  //     } else {
  //       dispatch({
  //         type: actions.SET_USER_SETTINGS,
  //         payload: {
  //           ...userProfile,
  //           loading: loadingStates.FAILED,
  //           data: null,
  //         },
  //       });
  //     }
  //   })();
  // }, [loggedInStatus.loggedIn]);

  // useEffect(() => {
  //   (async () => {
  //     dispatch({
  //       type: actions.SET_USER_PROFILE,
  //       payload: {
  //         ...userProfile,
  //         loading: loadingStates.LOADING,
  //         data: null,
  //       },
  //     });
  //     const response = await apiWrapWithoutData(getProfile());
  //     if (response && response.data && response.data.success) {
  //       dispatch({
  //         type: actions.SET_USER_PROFILE,
  //         payload: {
  //           ...userProfile,
  //           loading: loadingStates.FINISHED,
  //           data: response.data.profile,
  //         },
  //       });
  //       dispatch({
  //         type: actions.SET_LOGGED_IN_STATUS,
  //         payload: {
  //           loggedIn: true,
  //         },
  //       });
  //     } else {
  //       dispatch({
  //         type: actions.SET_USER_PROFILE,
  //         payload: {
  //           ...userProfile,
  //           loading: loadingStates.FAILED,
  //           data: null,
  //         },
  //       });
  //       dispatch({
  //         type: actions.SET_LOGGED_IN_STATUS,
  //         payload: {
  //           loggedIn: false,
  //         },
  //       });
  //     }
  //   })();
  // }, [loggedInStatus.loggedIn]);

  // useEffect(() => {
  //   (async () => {
  //     if (!loggedInStatus.loggedIn) return;
  //     const values = await apiWrap(getInitialValues());
  //     if (values && values.success) {
  //       dispatch({
  //         type: actions.SET_INITIAL_VALUES,
  //         payload: values.data,
  //       });
  //     }
  //   })();
  // }, [loggedInStatus.loggedIn]);
  // useEffect(() => {
  //   (async () => {
  //     if (!loggedInStatus.loggedIn) return;
  //     const values = await apiWrap(getAllTags());
  //     if (values && values.success) {
  //       dispatch({
  //         type: actions.SET_INITIAL_VALUES,
  //         payload: values.data,
  //       });
  //     }
  //   })();
  // }, [loggedInStatus.loggedIn]);
  // const location = useLocation();
  // useEffect(() => {
  //   pageViewEvent({
  //     pathname: location.pathname,
  //     search: location.search,
  //     // pageName:
  //   });
  //   // if (window && window.ga) {
  //   //   window.ga('set', 'page', location.pathname + location.search);
  //   //   window.ga('send', 'pageview');
  //   // }
  // }, [location]);

  // const userSettingsWithLoader = useSelector(selectUserSettings);
  // const userProfileWithLoader = useSelector(selectUserProfile);

  // useEffect(() => {
  //   if (userSettingsWithLoader.loading === loadingStates.FINISHED
  //       && userProfileWithLoader.loading === loadingStates.FINISHED
  //   ) {
  //     const userSettingsData = userSettingsWithLoader.data || {};
  //     const userProfileData = userProfileWithLoader.data || {};
  //     identifyUserEvent({
  //       email: userSettingsData.email,
  //       properties: {
  //         FIRSTNAME: userProfileData.name.split(' ')[0],
  //         LASTNAME: (userProfileData.name.split(' ')[1] || ''),
  //         plan: (userSettingsData.plan_type || ''),
  //         PRODUCT_SOURCE: productSources.oddup_app,
  //         ODDUP_MEMBER: true,
  //       },
  //     });
  //   }
  // }, [userSettingsWithLoader, userProfileWithLoader]);
  return (children);
};

MainWrapper.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};

export default MainWrapper;
