import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';

import axios from 'axios';
import * as Sentry from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import actions from './redux/actions';
import { getCookiesSession, setCookiesSession } from './utilities/cookiesSession';

if (!(window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')
|| window.location.href.includes('192.168'))) {
  Sentry.init({
    dsn: 'https://9947e9fc317c429ea9715b4906d1b28b@o907980.ingest.sentry.io/5850605',
  });
}

axios.interceptors.request.use((config) => {
  const axiosConfig = config;
  if (!axiosConfig.noTrailingSlash) {
    axiosConfig.url = config.url.replace(/\/?(\?|#|$)/, '/$1');
  }
  return axiosConfig;
});

axios.interceptors.response.use((response) => {
  const appStore = store.getState();
  if (response && response.data) {
    if (response.data.warning_message && response.data.warning_message === 'You are about to reach your daily limit') {
      store.dispatch({
        type: actions.SET_WARNING_RIBBON_SHOW,
        payload: {
          ...appStore.warningRibbon,
          show: true,
          exhausted: false,
        },
      });
    }
    if (response.data.warning_message && response.data.warning_message === 'You have reached your daily limit') {
      store.dispatch({
        type: actions.SET_WARNING_RIBBON_SHOW,
        payload: {
          ...appStore.warningRibbon,
          show: true,
          exhausted: true,
        },
      });
    }
  }
  return response;
},
(err) => {
  try {
    const { response } = err;
    const appStore = store.getState();
    if (response.status === 401) {
      if (response.data.success === false && response.data.errorCode === 'AUTH_FAILED') {
        if (appStore.loggedInStatus.loggedIn === true) {
          store.dispatch({
            type: actions.SET_LOGGED_IN_STATUS,
            payload: {
              loggedIn: false,
            },
          });
          const token = getCookiesSession('authToken');
          setCookiesSession('authToken', token, 0);
        }
      }
    }
    if (response.data.success === false && response.data.errorCode === 'ACCESS_LIMIT_EXCEED') {
      if (appStore.loggedInStatus.loggedIn === true) {
        store.dispatch({
          type: actions.SET_LIMIT_EXCEED_ERROR,
          payload: {
            ...appStore.limitExceedError,
            occurred: true,
            pathname: window.location.pathname,
          },
        });
        store.dispatch({
          type: actions.SET_WARNING_RIBBON_SHOW,
          payload: {
            ...appStore.warningRibbon,
            show: true,
            exhausted: true,
          },
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
  return Promise.reject(err);
});

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
