import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import '../tailwind.output.css';
import './RouteHandler.css';
import ReactTooltip from 'react-tooltip';
import MainWrapper from '../components/MainWrapper/MainWrapper';
import LazyLoading from '../components/LazyLoading/LazyLoading';
import Protected from '../components/Protected/Protected';
import LoginPage from './LoginPage/LoginPage';

const UsersListing = lazy(() => import('./Listings/users/UsersListing'));

const RouteHandler = () => (
  <MainWrapper>
    <ReactTooltip />
    <Suspense fallback={<LazyLoading />}>
      <Switch>
        <Route path="/" exact render={() => <LoginPage />} />
        <Protected exact path="/details/user/:userId" component={<UsersListing />} />
        <Protected path="/users" exact component={<UsersListing />} />
        <Protected path="/users/:searchedText" exact component={<UsersListing />} />
      </Switch>
    </Suspense>
  </MainWrapper>
);
export default RouteHandler;
