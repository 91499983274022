/**
 * React
 * Author: Ritesh Choudhary (ritesh@oddup.com)
 * Copyright © 2020-present Oddup. All rights reserved.
 *
 *
 */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { Redirect, useLocation } from 'react-router';
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import { loadingStates } from '../../utilities/general';
import { adminLogin } from '../../utilities/apis/adminApis/adminLogin';
import { apiWrapWithErrorWithData } from '../../utilities/apis/apiHelpers';
import actions from '../../redux/actions';
import { setCookiesSession } from '../../utilities/cookiesSession';
import { selectLoggedInStatus } from '../../redux/selectors';

const LoginPage = () => {
  const clientId = '244859344861-pnats5dg41io5loulg9umh236kr9cjn1.apps.googleusercontent.com';
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(loadingStates.NO_ACTIVE_REQUEST);
  const location = useLocation();
  const responseGoogleSuccess = (response) => {
    setLoading(loadingStates.LOADING);
    let token = null;
    try {
      if (response.credential) {
        token = response.credential;
      }
    } catch (e) {
      console.log(e);
      alert('Failed to log in. Please refresh page.');
    }
    if (token) {
      apiWrapWithErrorWithData(adminLogin({ accessToken: token })).then(
        (data) => {
          if (data.success && data.token) {
            setCookiesSession('authToken', data.token, 3600 * 6);
            dispatch({
              type: actions.SET_LOGGED_IN_STATUS,
              payload: {
                ...data,
                loggedIn: true,
              },
            });
          }
        },
      ).catch((e) => {
        console.log(e);
        setLoading(loadingStates.FAILED);
      });
    }
  };
  useGoogleOneTapLogin({
    onError: (error) => console.log(error),
    onSuccess: (response) => console.log(response),
    googleAccountConfigs: {
      client_id: clientId,
      callback: responseGoogleSuccess,
    },
  });
  useEffect(() => {
    window.handleGoogleLogin = responseGoogleSuccess;
    return () => {
      window.handleGoogleLogin = () => alert('Login not allowed at this point.');
    };
  }, []);
  const loggedInStatus = useSelector(selectLoggedInStatus);
  if (loggedInStatus.loggedIn) {
    let goToPage = null;
    try {
      goToPage = location.state.from;
    } catch (e) {
      console.log(e);
    }
    return <Redirect to={goToPage || '/users'} />;
  }
  return (
    <div className=" w-screen h-screen flex justify-center items-center">
      {loading === loadingStates.LOADING ? <BeatLoader size={10} color="blue" />
        : (
          <div>
            <div
              id="g_id_onload"
              data-client_id={clientId}
              data-callback="handleGoogleLogin"
              data-auto_prompt="true"
            />
            <div
              className="g_id_signin"
              data-type="standard"
              data-size="large"
              data-theme="outline"
              data-text="sign_in_with"
              data-shape="rectangular"
              data-logo_alignment="left"
            />
          </div>
        )}
    </div>
  );
};

export default LoginPage;
